import React from "react";
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { isExpired, decodeToken } from "react-jwt";
import { TabData } from "../fragments/ItemForm/ItemForm";


export function ProvideAuth(children: any) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {children.children}
    </authContext.Provider>
  )
}

export const useAuth = () => {
  const context = React.useContext(authContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};


export function useProvideAuth() {
  const [user, setUser] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [error, setError] = React.useState('');

  const login = (username: string, password: string, cb: Function) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    };

    fetch(`${process.env.REACT_APP_WP_API}jwt-auth/v1/token`, requestOptions)
      .then(response => {
        response.json().then(json => {
          if (typeof json.token != 'undefined') {
            setFromToken(json.token);
            cb('');
          }
          else if (typeof json.message != undefined) {
            cb(json.code);
          }
          else {
            cb('Unknown Error');
          }
        }).catch(reason => {
          cb('Unknown Error');
        });
      });
  }

  const resetPassword = (email_address: string, cb: Function) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email_address })
    };

    fetch(`${process.env.REACT_APP_WP_API}supplychain/v1/sendPasswordReset`, requestOptions)
      .then(response => {
        response.json().then(json => {
          console.log(json);
          if (typeof json.errors != 'undefined') {

            cb(json.errors);
          }else {
            cb(json);

          }

        }).catch(reason => {
          cb('');
        });
      });
  }



  const checkLoginStatus = (cb:Function) => {
    let token = localStorage.getItem('jwt');

    if (token != null) {
      let expired = isExpired(token);
      if (expired) {
        setLoggedIn(false);
        setUser(null);
        cb(false);
        return;
      }
      else {
        setFromToken(token);
        cb(true);
        return;
      }
    }
    cb(false);
  }

  const getError = () => {
    return error;
  }

  const setFromToken = (token:string) => {
    localStorage.setItem('jwt', token);
    let decoded = decodeToken(token);

    

    setLoggedIn(true);
    setUser(decoded.user);
  }

  const logout = (cb:Function) => {

    getWPAPI().get('supplychain/v1/logout');



    localStorage.removeItem('jwt');
    cb();
  }

  const getWPAPI = ():AxiosInstance => {
    const WPAPI = axios.create({
      baseURL: process.env.REACT_APP_WP_API
    });
    WPAPI.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');

    return WPAPI;
  }

  const getValidNodes = () => {
    return getWPAPI().get('supplychain/v1/getValidNodes');
  }

  const getNodeDiagram = (id: string) => {
    return getWPAPI().get('supplychain/v1/diagram/' + id);
  }

  const getNodeGeomap = (id: string) => {
    return getWPAPI().get('supplychain/v1/geomap/' + id);
  }
  
  const saveItems = (items:Array<string>) => {
    return getWPAPI().post('supplychain/v1/saveItems', items).then(response => {
    });
  }
  
  const getItemForm = (id: string):Promise<AxiosResponse<TabData>> => {
    return getWPAPI().get<TabData>('supplychain/v1/getItemForm/' + id);
  }
  
  const saveItemForm = (id: string, data: any):Promise<AxiosResponse<TabData>> => {
    return getWPAPI().post('supplychain/v1/saveItemForm/' + id, data);
  }

  const getItems = (type: string):Promise<AxiosResponse<any>> => {
    return getWPAPI().get('supplychain/v1/getItems/' + type);
  }

  const deleteNode = (id: string):Promise<AxiosResponse<any>> => {
    return getWPAPI().get('supplychain/v1/deleteNode/' + id);
  }

  const deleteModel = (id: string):Promise<AxiosResponse<any>> => {
    return getWPAPI().get('supplychain/v1/deleteModel/' + id);
  }

  const deleteLink = (source: string, target: string):Promise<AxiosResponse<any>> => {
    return getWPAPI().get('supplychain/v1/deleteLink/' + source + '/' + target);
  }

  return {
    loggedIn: loggedIn,
    user: user,
    getError,
    login,
    resetPassword,
    logout,
    checkLoginStatus,
    getNodeDiagram,
    getNodeGeomap,
    saveItems,
    getItemForm,
    getItems,
    saveItemForm,
    deleteNode,
    deleteLink,
    getValidNodes,
    deleteModel
  }
}

export const authContext = React.createContext<AuthState|undefined>(undefined);



interface AuthState {
  loggedIn: boolean;
  user: User|null;
  getError: Function;
  login: Function;
  resetPassword:Function;
  logout: Function;
  checkLoginStatus: Function;
  getNodeDiagram: Function;
  getNodeGeomap: Function;
  saveItems: Function;
  getItemForm: Function;
  getItems: Function;
  saveItemForm: Function;
  deleteNode: Function;
  deleteLink: Function;
  getValidNodes: Function;
  deleteModel: Function;
};

interface User {
  username: string
};