import React, { useEffect, useRef, useState } from 'react';
import styles from './GeoViewSidebar.module.scss';
import { NavLink } from "react-router-dom";
import { useAppContext } from '../../services/AppContext';
import { ItemForm } from '../ItemForm/ItemForm';
import Select from 'react-select';

export interface ServerParentList {
	customers:{id:string, name:string}[]; 
	products:{id:string, name:string}[];
}

export interface GeoList {
	type: string,
	nodeID: string,
	modelID: string,
	coords: number[],
	name: string,
}

interface SelectOptions {
	label: string;
	options:{label: string, value: string}[];
};

interface GeoViewSidebarProps {
		parentList: ServerParentList;
		changeProduct: Function;
		currParentID: string;
		view: Function;
		nodeList: GeoList[];
};

export const GeoViewSidebar = (props: GeoViewSidebarProps) => {
		const appContext = useAppContext();
		const [parentOptions, setParentOptions] = useState<SelectOptions[]>([]);
		const select = useRef<Select>(null);

		const createFailure = () => {

		}

		useEffect(() => {
			let customerOptions:{label: string, value: string}[] = [];
			let productOptions:{label: string, value: string}[] = [];

			for (let customer of props.parentList.customers) {
				customerOptions.push({value: customer.id, label: customer.name});
			}
			for (let product of props.parentList.products) {
				productOptions.push({value: product.id, label: product.name});
			}

			setParentOptions([
				{
					label: 'Products',
					options: productOptions,
				},
				{
					label: 'Customers',
					options: customerOptions,
				}]);

		}, [props.parentList]);
		
		const handleInputChange = (event:any) =>{
			props.changeProduct(event.value);
		}
		
		const createSuccess = () => {
			props.changeProduct(props.currParentID)
		}

		const edit = (model: string) => {
			appContext.setModalContent(<ItemForm onSuccess={createSuccess} onFailure={createFailure} id={model} />);

			appContext.showModal();
		}

		const view = (node: string) => {
			props.view(node);
		}

		// const loadProductOptions =(options:[object]) => {
		//         let options= [];
		//         for(let product of props.productList) {
		//             options.push({value:product.id,label:product.name});
		//         }
		//         return options;
				
		// }

		return (
				
		<div className={styles.geoViewSidebarWrapper}>
				<div className={styles.sidebarContent}>
						<ul className={styles.geoViewMapMenu}>
								<li>
									<NavLink to="/nodes">SUPPLY CHAIN NODE MAP</NavLink>
									<NavLink to="/geomap" activeClassName={styles.sidebarActiveLink}>SUPPLY CHAIN GEO MAP</NavLink>
								</li>
								
						</ul>
						<div className={styles.geoViewMapProductDropdownWrapper}>
								<label>
										<span className={styles.sidebarLabel}>VIEW</span>
										<Select ref={select} name="product_supply_chain" isSearchable={true} options={parentOptions} className={styles.productDropdown} onChange={event => handleInputChange(event)}/>

								</label>
						</div>

						<div className={styles.geoNodeList}>
						{(() => {
							let list:JSX.Element[] = [];

							for (let node of props.nodeList) {
								if (typeof node.coords != 'undefined') {
									list.push(
										<div key={node.nodeID} className={styles.listMarker}>
											<div className={styles.title}>{node.name}</div>
											<div className={styles.edit} onClick={() => {edit(node.modelID)}}>EDIT</div>
											<div className={styles.view} onClick={() => {view(node.nodeID)}}>View</div>
										</div>
									);
								}
								else {
									list.push(
										<div key={node.nodeID} className={styles.listMarker}>
											<div className={styles.title}>{node.name}</div>
											<div className={styles.edit} onClick={() => {edit(node.modelID)}}>EDIT</div>
											<div className={[styles.view, styles.disable].join(' ')}>View</div>
											<div className={styles.warning}>* location not found</div>
										</div>
									);
								}
							}

							return list;
						})()}
						</div>

				</div>
		</div>
		)
};