import React from 'react';
import styles from './Dashboard.module.scss';
import { Link } from "react-router-dom";

interface DashboardProps {
}
export const Dashboard = (props: DashboardProps) => {
    return (
        <div className={styles.dashboardWrapper}>
            <div className={styles.sectionHeader}>
                <div className={styles.contentWrapper}>
                    <h1>DASHBOARD</h1>
                </div>
            </div>
            <div className={[styles.dashboardMenu, styles.contentWrapper].join(' ')}>
                <div className={styles.dashboardMenuRow}>

                    <Link to="/report-shipping">
                        <div className={styles.dashboardMenuItem}>
                            <h3>SHIPPING<br />INTERMEDIARIES</h3>
                            <div className={styles.dashboardMenuItemDetail}>
                                Enter/Edit<br />
                                    Shipping Intermediaries
                                </div>
                        </div>
                    </Link>
                    <Link to="/report-suppliers">
                        <div className={styles.dashboardMenuItem}>
                            <h3>SUPPLIERS</h3>
                            <div className={styles.dashboardMenuItemDetail}>
                                Enter/Edit<br />
                                    Suppliers
                                </div>
                        </div>
                    </Link>
                    <Link to="/report-products">
                        <div className={styles.dashboardMenuItem}>
                            <h3>PRODUCTS/ITEMS</h3>
                            <div className={styles.dashboardMenuItemDetail}>
                                Enter/Edit<br />
                                    Shipping Intermediaries
                                </div>
                        </div>
                    </Link>
                    <Link to="/report-suppliers">
                        <div className={styles.dashboardMenuItem}>
                            <h3>CUSTOMERS</h3>
                            <div className={styles.dashboardMenuItemDetail}>
                                Enter/Edit<br />
                                    Customers
                                </div>
                        </div>
                    </Link>
                    <div className={styles.clearFix}></div>

                </div>
                <div className={styles.dashboardMenuRow}>
                    <a href="/profile/company">
                        <div className={[styles.dashboardMenuItem, styles.dashboardMenuItemWide].join(' ')}>
                            <h3>COMPANY PROFILE</h3>
                            <div className={styles.dashboardMenuItemDetail}>
                                Enter/Edit<br />
                                    Company Profile Information
                                </div>
                        </div>
                    </a>
                    <Link to="/report-suppliers">
                        <div className={[styles.dashboardMenuItem, styles.dashboardMenuItemWide].join(' ')}>
                            <h3>REPORTS</h3>
                            <div className={styles.dashboardMenuItemDetail}>
                                Run Reports
                                </div>
                        </div>
                    </Link>
                    <Link to="/nodes">
                        <div className={[styles.dashboardMenuItem, styles.dashboardMenuItemWide].join(' ')} >
                            <h3>NODE MAP BUILDER</h3>
                            <div className={styles.dashboardMenuItemDetail}>
                                Use Nodes View to Build <br />
                                    New or Edit Existing Supply Chain
                                </div>
                        </div>
                    </Link>
                    <div className={styles.clearFix}></div>

                </div>
            </div>
        </div>
    );
};