import React, { useEffect, useState } from 'react';
import styles from './NodeViewSidebar.module.scss';
import { NavLink } from "react-router-dom";
import { useAppContext } from '../../services/AppContext';
import { ItemForm } from '../ItemForm/ItemForm';
import Select from 'react-select';

export interface ServerParentList {
	customers:{id:string, name:string}[]; 
	products:{id:string, name:string}[];
}

interface SelectOptions {
	label: string;
	options:{label: string, value: string}[];
};

interface NodeViewSidebarProps {
		parentList: ServerParentList;
		changeProduct: Function;
}
export const NodeViewSidebar = (props: NodeViewSidebarProps) => {
		const appContext = useAppContext();
		const [parentOptions, setParentOptions] = useState<SelectOptions[]>([]);

		const createFailure = () => {

		}

		useEffect(() => {
			let customerOptions:{label: string, value: string}[] = [];
			let productOptions:{label: string, value: string}[] = [];

			for (let customer of props.parentList.customers) {
				customerOptions.push({value: customer.id, label: customer.name});
			}
			for (let product of props.parentList.products) {
				productOptions.push({value: product.id, label: product.name});
			}

			setParentOptions([
				{
					label: 'Products',
					options: productOptions,
				},
				{
					label: 'Customers',
					options: customerOptions,
				}]);

		}, [props.parentList]);
		
		const handleInputChange = (event:any) =>{
				props.changeProduct(event.value);

		}
		
		const createSuccess = (id: string) => {
				props.changeProduct(id);
		}
		// const loadProductOptions =(options:[object]) => {
		//         let options= [];
		//         for(let product of props.productList) {
		//             options.push({value:product.id,label:product.name});
		//         }
		//         return options;
				
		// }
		const createProduct = () => {
				appContext.setModalContent(<ItemForm onSuccess={createSuccess} onFailure={createFailure} id="product" />);

				appContext.showModal();
		}

		const createCustomer = () => {
				appContext.setModalContent(<ItemForm onSuccess={createSuccess} onFailure={createFailure} id="customer" />);

				appContext.showModal();
		}

		return (
		<div className={styles.nodeViewSidebarWrapper}>
				<div className={styles.sidebarContent}>
						<ul className={styles.nodeViewMapMenu}>
								<li>
										<NavLink to="/nodes" activeClassName={styles.sidebarActiveLink}>SUPPLY CHAIN NODE MAP</NavLink>
										<NavLink to="/geomap" >SUPPLY CHAIN GEO MAP</NavLink>
								</li>
								
						</ul>
						<div className={styles.addProductWrapper}>
								<button onClick={createProduct} className={styles.nodeViewCreateProduct}>Create a Product</button>
								<button onClick={createCustomer} className={styles.nodeViewCreateProduct}>Create a Customer</button>
						</div>
						<div className={styles.nodeViewMapProductDropdownWrapper}>
								<label>
										<span className={styles.sidebarLabel}>VIEW</span>
										<Select name="product_supply_chain" isSearchable={true} options={parentOptions} className={styles.productDropdown} onChange={event => handleInputChange(event)}/>

								</label>
						</div>

					 

				</div>
		</div>
		)
};