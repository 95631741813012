import * as React from 'react';
import { MouseEvent, RefObject, useEffect, useState } from 'react';
import { DiagramEngine, LinkWidget, PointModel } from '@projectstorm/react-diagrams-core';
import { DefaultLinkSegmentWidget, DefaultLinkPointWidget } from '@projectstorm/react-diagrams';
import { MyLinkModel } from './MyLinkModel';
import { useAuth } from '../../../services/AuthContext';
import { useAppContext } from '../../../services/AppContext';
import { IoCloseCircle } from 'react-icons/io5';
import styles from './MyLink.module.scss';

export interface MyLinkProps {
	link: MyLinkModel;
	diagramEngine: DiagramEngine;
	pointAdded?: (point: PointModel, event: MouseEvent) => any;
}


export function MyLinkWidget (props: MyLinkProps) {
	let refPaths: RefObject<SVGPathElement>[] = [];
	const [selected, setselected] = useState<boolean>(false);
	const authState = useAuth();
  const appState = useAppContext();

	useEffect(() => {
		if (props.link.getLabels().length === 0) {
			//props.link.addMyLabel();
		}
	}, []);

	useEffect(() => {
		let newRefPaths: SVGPathElement[] = [];

		for(let refPath of refPaths) {
			if (refPath.current) {
				newRefPaths.push(refPath.current);
			}
		}

		props.link.setRenderedPaths(newRefPaths);

		return () => {
			props.link.setRenderedPaths([]);
		}
	},[props]);

	const generatePoint = (point: PointModel): JSX.Element => {
		let color = props.link.getOptions().selectedColor;
		let notUndefinedColor = '#ccc';

		if (typeof color !== 'undefined') {
			notUndefinedColor = color;
		}

		return (
			<DefaultLinkPointWidget
				key={point.getID()}
				point={point as any}
				colorSelected={notUndefinedColor}
				color={props.link.getOptions().color}
			/>
		);
	}

	const generateLink = (path: string, extraProps: any, id: string | number): JSX.Element => {
		const ref = React.createRef<SVGPathElement>();
		refPaths.push(ref);
		return (
			<DefaultLinkSegmentWidget
				key={`link-${id}`}
				path={path}
				selected={selected}
				diagramEngine={props.diagramEngine}
				factory={props.diagramEngine.getFactoryForLink(props.link)}
				link={props.link}
				forwardRef={ref}
				onSelection={(selected) => {
					setselected(selected);
				}}
				extras={extraProps}
			/>
		);
	}

	const deleteLink = () => {

    authState.deleteLink(props.link.getSourcePort().getID(), props.link.getTargetPort().getID()).then(() => {
      props.link.remove();

      appState.diagramEngine.repaintCanvas();
    });
  }

	//ensure id is present for all points on the path
	var points = props.link.getPoints();
	var paths = [];
	refPaths = [];
	let middleX = 0;
	let middleY = 0;

	if (points.length === 2) {
		let linkPath = generateLink(
			props.link.getSVGPath(),
			{
				onMouseDown: (event:any) => {
				}
			},
			'0'
		);

		let validPath = true;
		for (let point of props.link.getPoints()) {
			if (point.getPosition().x === null || point.getPosition().y === null) {
				validPath = false;
			}
		}

		if (validPath) {
			let points = props.link.getPoints();
			if (points.length == 2) {
				let minX = points[0].getX();
				let maxX = points[0].getX();
				let minY = points[0].getY();
				let maxY = points[0].getY();

				if (points[1].getX() > maxX) {
					maxX = points[1].getX();
				}
				if (points[1].getY() > maxY) {
					maxY = points[1].getY();
				}
				if (points[1].getX() < minX) {
					minX = points[1].getX();
				}
				if (points[1].getY() < minY) {
					minY = points[1].getY();
				}

				middleY = (maxY - minY) / 2 + minY;
				middleX = (maxX - minX) / 2 + minX;
			}

			paths.push(linkPath);
		}

		// draw the link as dangeling
		if (props.link.getTargetPort() === null) {
			let danglelingPath = generatePoint(points[1]);
			paths.push(danglelingPath);
		}
	} else {
		//draw the multiple anchors and complex line instead
		for (let j = 0; j < points.length - 1; j++) {
			paths.push(
				generateLink(
					LinkWidget.generateLinePath(points[j], points[j + 1]),
					{
						'data-linkid': props.link.getID(),
						'data-point': j,
						onMouseDown: (event: MouseEvent) => {
							console.log('make popup here 2');
						}
					},
					j
				)
			);
		}

		//render the circles
		for (let i = 1; i < points.length - 1; i++) {
			paths.push(generatePoint(points[i]));
		}

		if (props.link.getTargetPort() === null) {
			paths.push(generatePoint(points[points.length - 1]));
		}
	}

	return (
		<g data-default-link-test={props.link.getOptions().testName}>
			{paths}
			<foreignObject width={middleX + 50} height={middleY + 50}>
				<div className={styles.deleteLink} onClick={deleteLink} style={{position: 'absolute', top: middleY-7, left: middleX-7}}><IoCloseCircle /></div>
			</foreignObject>
		</g>
		);
}
