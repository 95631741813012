import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../services/AuthContext";
import { LoggedInShow } from "../LoggedIn/LoggedInShow";
import styles from './Header.module.scss';
import { useHistory } from "react-router-dom";

import { IoPerson } from "react-icons/io5";

type MyProps = {
};

export function Header(props: MyProps) {
	const history = useHistory();

	const auth = useAuth();

	const logout = (e: any) => {
		auth.logout(() => {

			history.push("/");
			window.location.reload();
		});

		
		e.preventDefault();
	}

	return (
		<div className={styles.topMenu}>
			<div className={styles.logoWrapper}><Link to="/dashboard"><img src="/wp-content/themes/supply-chain/img/logo.svg" alt="Links Tool" /></Link></div>
			<div className={styles.headerMenu}>
				<ul>
					<LoggedInShow showWhenLoggedIn={false}>
						<li><Link to="/login">SIGN IN</Link></li>
					</LoggedInShow>
					<LoggedInShow showWhenLoggedIn={true}>
						<li><Link to="/report-products">DATA/RUN REPORTS</Link></li>
						<li><Link to="/nodes">MAP A SUPPLY CHAIN</Link></li>
					</LoggedInShow>
				</ul>

			</div>
			<div className={styles.userMenu}>
				<LoggedInShow showWhenLoggedIn={true}>

					<ul>
						<li>
							<Link to="/dashboard">DASHBOARD</Link>
						</li>
						<li><a href="/profile/company"><IoPerson /></a></li>
						<li><a className={styles.logoutBtn} onClick={logout}>SIGN OUT</a></li>

					</ul>
				</LoggedInShow>

			</div>
			<div className={styles.clearFix}></div>
		</div>
	);
}