import React, { useEffect, useState,useRef } from 'react';
import styles from './GeoMap.module.scss';
import { useAuth } from '../../services/AuthContext';
import { GeoViewSidebar, ServerParentList, GeoList } from '../../fragments/GeoViewSidebar/GeoViewSidebar';
import { useAppContext } from '../../services/AppContext';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { along, length, Feature, Properties } from '@turf/turf';
import { stringify } from 'node:querystring';

mapboxgl.accessToken = process.env.MAPBOX_TOKEN!;

interface GeoMapProps {
}
export function GeoMap(props: GeoMapProps) {
	const auth = useAuth();
	const app = useAppContext();
	const [parentList, setParentList] = useState<ServerParentList>({customers: [], products: []});
	const [geoList, setGeoList] = useState<GeoList[]>([]);
	const map = useRef<mapboxgl.Map|null>(null);
	const mapContainer = useRef(null);
	const markers = useRef<{[key: string]: {marker: mapboxgl.Marker, coords: [number,number]}}>({});
	const [currParentID, setCurrParentID] = useState<string>('');

	const [lng, setLng] = useState(-98.4703457399514);
	const [lat, setLat] = useState(53.4604863996);
	const [zoom, setZoom] = useState(4);

	useEffect(() => {
		const attachMap = (mapContainer: React.RefObject<HTMLDivElement>) => {
		
			map.current = new mapboxgl.Map({
				container: mapContainer.current!,
				style: 'mapbox://styles/supplychaincanada/ckpbtxa1g63ng17t9ttwhmp4j',
				center: [lng, lat],
				zoom: zoom,
			});

			map.current.on('load', function () {
				if (map.current != null) {
					map.current.addSource('route', {
						type: 'geojson',
						data: {
							type: 'Feature',
							properties: {},
							geometry: {
								type: 'MultiLineString',
								coordinates: [
								]
							}
						}
					});

					map.current.addLayer({
						id: 'route',
						type: 'line',
						source: 'route',
						layout: {
							"line-join": 'round',
							"line-cap": 'round',
						},
						paint: {
							"line-color": '#888',
							"line-width": 3,
						},
					});
				}
			});

			getGeomap('');
			//map.addControl(new mapboxgl.NavigationControl(), 'top-left');
		}
		!map.current && attachMap(mapContainer);
	}, []);

	const clearMarkers = () => {
		for (let marker in markers.current) {
			markers.current[marker].marker.remove();
		}
		markers.current = {};
	}

	const getSameGeomap = () => {
		getGeomap(currParentID);
	}

	const getGeomap = (id: string) => {
		auth.getNodeGeomap(id).then((result:any) => {
			setParentList(result.data.parents);
			setCurrParentID(result.data.currParentID);

			if (map.current != null) {
				let geoList = [];
				clearMarkers();

				setGeoList(result.data.model.nodes);

				for(let node of result.data.model.nodes) {
					if (typeof node.coords != 'undefined') {
						let el = document.createElement('div');
						el.className = styles.marker;

						// make a marker for each feature and add to the map
						let marker = new mapboxgl.Marker(el, {
							offset: [0, -32/2],
						});

						let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
							[
								'<div class="' + styles.markerContent + '">',
								'<strong>Name:</strong> ' + node.name + '<br />',
								'<strong>Type:</strong> ' + node.type + '<br />',
								'</div>',
							].join('\n')
						);

						marker.setLngLat(new mapboxgl.LngLat(node.coords[1],node.coords[0])).addTo(map.current);
						marker.setPopup(popup);

						marker.getElement().addEventListener('click', function(this: HTMLElement) {
							for(let key in markers.current) {
								markers.current[key].marker.getElement().classList.remove(styles.current);
							}
							this.classList.add(styles.current);
						});

						markers.current[node.nodeID] = {marker: marker, coords: [node.coords[1],node.coords[0]]};
					}
					else {

					}
				}

				let multiLineString:number[][][] = [];

				for(let link of result.data.model.links) {
					if (typeof markers.current[link.from] != 'undefined' && typeof markers.current[link.to] != 'undefined') {
						multiLineString.push(generateArcFromPoints(markers.current[link.from].coords, markers.current[link.to].coords));
					}
				}

				setSourceData(multiLineString);
			}

		});
	}

	const generateArcFromPoints = (from: [number, number], to: [number, number]) => {
		let lineFeature:Feature<any, Properties> = {
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'LineString',
				properties: {},
				coordinates: [from, to],
			}
		};

		let lineLength = length(lineFeature);
		let arc = [];

		let steps = 500;

		for (let i = 0; i < lineLength; i+= lineLength/steps) {
			let point = along(lineFeature, i)
			arc.push([point.geometry.coordinates[0], point.geometry.coordinates[1]]);
			// https://macwright.com/2016/09/26/the-180th-meridian.html
		}

		return arc;
	}

	const setSourceData = (multiLineString: number[][][]) => {

		if (map.current != null) {
			let source = map.current.getSource('route');
			// TODO: Fix race condition where source is not yet set
			if (typeof source != 'undefined' && source.type == 'geojson') {
				source.setData({
					type: 'Feature',
					properties: {},
					geometry: {
						type: 'MultiLineString',
						coordinates: multiLineString,
					}
				});
			}
			else {
				window.setTimeout(() => {
					setSourceData(multiLineString);
				}, 100);
			}
		}
		else {
			window.setTimeout(() => {
				setSourceData(multiLineString);
			}, 100);
		}
	}

	const viewNode = (node: string) => {
		map.current?.flyTo({
			center: markers.current[node].coords
		});

		for(let key in markers.current) {
			markers.current[key].marker.getElement().classList.remove(styles.current);
			if (markers.current[key].marker.getPopup().isOpen()) {
				markers.current[key].marker.togglePopup();
			}
		}

		markers.current[node].marker.togglePopup();

		markers.current[node].marker.getElement().classList.add(styles.current);
	}


	return (
		
		<div className={styles.canvasWrapper}>
			<GeoViewSidebar parentList={parentList} currParentID={currParentID} nodeList={geoList} changeProduct={getGeomap} view={viewNode} />
			<div ref={mapContainer} className={styles.mabBoxWrapper} />
		</div>
	);
}