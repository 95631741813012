import {
  State,
  Action,
  InputType,
  DragCanvasState,
} from '@projectstorm/react-canvas-core';

import {
  PortModel,
} from '@projectstorm/react-diagrams-core';

import { MyDragDiagramItemsState } from './DragDiagramItemsState';

import { DragNewLinkState } from './DragNewLinkState';

export class DiagramState extends State {
	dragCanvas: DragCanvasState;
	dragNewLink: DragNewLinkState;
	dragItems: MyDragDiagramItemsState;


  constructor() {
    super({
      name: 'default-diagrams'
    });
    //this.childStates = [new SelectingState()];
    this.dragCanvas = new DragCanvasState();
    this.dragNewLink = new DragNewLinkState();
    this.dragItems = new MyDragDiagramItemsState();

    // determine what was clicked on
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: any) => {
          if (event.event.button !== 0) {
            // we don't want to do anything on right-clicks
            return;
          } else {
            const element = this.engine.getActionEventBus().getModelForEvent(event);

            // the canvas was clicked on, transition to the dragging canvas state
            if (!element) {
                this.transitionWithEvent(this.dragCanvas, event);
            }
            // initiate dragging a new link
            else if (element instanceof PortModel) {
                this.transitionWithEvent(this.dragNewLink, event);
            }
            // move the items (and potentially link points)
            else {
                this.transitionWithEvent(this.dragItems, event);
            }
          }
        }
      })
    );
  }
}