import React, { useState } from 'react';
import style from './NodeMenu.module.scss';
import { IoEllipsisVerticalSharp } from "react-icons/io5";

type MyProps = {
	children: any;
};

export function  NodeMenu (props: MyProps) {
	const [showMenu, setshowMenu] = useState(false);
	const [myTimeout, setMyTimeout] = useState<ReturnType<typeof setTimeout>|null>(null);

	const toggleShowMenu = () => {
		setshowMenu(!showMenu);
	}

	const mouseEnter = () => {
		if (myTimeout !== null) {
			clearTimeout(myTimeout);
			setMyTimeout(null);
		}
	}

	const mouseLeave = () => {
		setMyTimeout(setTimeout(() => {
			setshowMenu(false);
		}, 300));
	}

	return (
		<div className={[style.popUpMenu, 'cancel-event'].join(' ')} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
			<div className={style.menuButton} onClick={toggleShowMenu}>
				<IoEllipsisVerticalSharp />
			</div>
			<div className={[style.menu, 'cancel-event', showMenu ? style.active : ''].join(' ')}>
				{props.children}
			</div>
		</div>
	);
}