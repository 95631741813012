import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "../../services/AuthContext";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  type?: string;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        auth.loggedIn ? (
          typeof props.type !== 'undefined' ? (
            <Component {...routeProps} type={props.type} />
          ) : (
            <Component {...routeProps} />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location.pathname }
            }}
          />
        )
      }
    />
  );
};