import { Action, InputType, MoveItemsState } from '@projectstorm/react-canvas-core';
import * as _ from 'lodash';
import {
	PointModel,
	DiagramEngine,
	PortModel,
	LinkModel,
} from '@projectstorm/react-diagrams-core';
import { NodeModel } from '@projectstorm/react-diagrams';
import axios from 'axios';

export class MyDragDiagramItemsState extends MoveItemsState<DiagramEngine> {
	constructor() {
		super();
		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (event: any) => {
					const item = this.engine.getMouseElement(event.event);

					if (item instanceof NodeModel) {
						let itemsToSave:any[] = [];

						itemsToSave.push(item.serialize());

						const WPAPI = axios.create({
							baseURL: process.env.REACT_APP_WP_API
						});
						WPAPI.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');

						WPAPI.post('supplychain/v1/saveItems', itemsToSave).then(response => {
						});
					}

					if (item instanceof PortModel) {
						_.forEach(this.initialPositions, (position) => {
							if (position.item instanceof PointModel) {
								const link = position.item.getParent() as LinkModel;

								// only care about the last links
								if (link.getLastPoint() !== position.item) {
									return;
								}
								if (link.getSourcePort().canLinkToPort(item)) {
									link.setTargetPort(item);

									item.reportPosition();
									this.engine.repaintCanvas();
								}
							}
						});
					}
				}
			})
		);
	}
}
