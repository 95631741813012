import {
	AbstractDisplacementState,
	AbstractDisplacementStateEvent,
	Action,
	InputType
} from '@projectstorm/react-canvas-core';

import {
	PortModel,
	LinkModel,
	DiagramEngine,
} from '@projectstorm/react-diagrams-core';

import { ChooseNodeModel } from '../entities/nodes/choose/ChooseNodeModel';
import { MyPortModel } from '../entities/ports/MyPortModel';
import axios from 'axios';


export interface DragNewLinkStateOptions {
	/**
	 * If enabled, the links will stay on the canvas if they dont connect to a port
	 * when dragging finishes
	 */
	allowLooseLinks?: boolean;
	/**
	 * If enabled, then a link can still be drawn from the port even if it is locked
	 */
	allowLinksFromLockedPorts?: boolean;
}

export class DragNewLinkState extends AbstractDisplacementState<DiagramEngine> {
	port: PortModel | null;
	link: LinkModel | null;
	config: DragNewLinkStateOptions;

	constructor(options: DragNewLinkStateOptions = {}) {
		super({ name: 'drag-new-link' });

		this.port = null;
		this.link = null;

		this.config = {
			allowLooseLinks: true,
			allowLinksFromLockedPorts: false,
			...options
		};

		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event:any) => {
					this.port = this.engine.getMouseElement(event.event) as PortModel;
					if (!this.config.allowLinksFromLockedPorts && this.port.isLocked()) {
						this.eject();
						return;
					}
					this.link = this.port.createLinkModel();

					if (this.port instanceof MyPortModel) {
						if (this.port.getOptions().in) {
							this.eject();
							return;
						}
					}

					// if no link is given, just eject the state
					if (!this.link) {
						this.eject();
						return;
					}
					this.link.setSelected(true);
					this.link.setSourcePort(this.port);
					this.engine.getModel().addLink(this.link);
					this.port.reportPosition();
				}
			})
		);

		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (event: any) => {
					const model = this.engine.getMouseElement(event.event);
					// check to see if we connected to a new port
					if (model instanceof PortModel) {
						if (this.port != null && this.link != null && this.port.canLinkToPort(model)) {
							this.link.setTargetPort(model);

							const WPAPI = axios.create({
								baseURL: process.env.REACT_APP_WP_API
							});
							WPAPI.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');

							WPAPI.get('supplychain/v1/linkNodes/' + this.link.getSourcePort().getID() + '/' + this.link.getTargetPort().getID()).then(response => {
							});

							model.reportPosition();
							this.engine.repaintCanvas();
							return;
						} else if (this.link != null) {
							this.link.remove();
							this.engine.repaintCanvas();
							return;
						}
					}
					else if (this.link != null && this.port instanceof MyPortModel && !this.port.getOptions().in){
						const newNode = new ChooseNodeModel('Choose a Type');
						let inPort = newNode.addInPort('In');

						newNode.setPosition(this.link.getLastPoint().getX(), this.link.getLastPoint().getY());

						this.link.setTargetPort(inPort);

						this.engine.getModel().addNode(newNode);
					}

					if (this.link != null && !this.config.allowLooseLinks) {
						this.link.remove();
						this.engine.repaintCanvas();
					}

					console.log(event);
				}
			})
		);
	}

	/**
	 * Calculates the link's far-end point position on mouse move.
	 * In order to be as precise as possible the mouse initialXRelative & initialYRelative are taken into account as well
	 * as the possible engine offset
	 */
	fireMouseMoved(event: AbstractDisplacementStateEvent): any {
		if (this.port != null && this.link != null) {
			const portPos = this.port.getPosition();
			const zoomLevelPercentage = this.engine.getModel().getZoomLevel() / 100;
			const engineOffsetX = this.engine.getModel().getOffsetX() / zoomLevelPercentage;
			const engineOffsetY = this.engine.getModel().getOffsetY() / zoomLevelPercentage;
			const initialXRelative = this.initialXRelative / zoomLevelPercentage;
			const initialYRelative = this.initialYRelative / zoomLevelPercentage;
			const linkNextX = portPos.x - engineOffsetX + (initialXRelative - portPos.x) + event.virtualDisplacementX;
			const linkNextY = portPos.y - engineOffsetY + (initialYRelative - portPos.y) + event.virtualDisplacementY;

			this.link.getLastPoint().setPosition(linkNextX, linkNextY);
		}
		this.engine.repaintCanvas();
	}
}
