import { DefaultLinkFactory } from "@projectstorm/react-diagrams";
import React from "react";
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { MyLinkModel } from "./MyLinkModel";
import { MyLinkWidget } from "./MyLinkWidget";

namespace S {
	export const Keyframes = keyframes`
		from {
			stroke-dashoffset: 0;
		}
		to {
			stroke-dashoffset: 24;
		}
	`;

	const selected = css`
		stroke-dasharray: 10, 2;
		animation: ${Keyframes} 1s linear infinite;
	`;

	export const Path = styled.path<{ selected: boolean }>`
		${(p) => p.selected && selected};
		fill: none;
		pointer-events: all;
	`;
}

export class MyLinkFactory<Link extends MyLinkModel = MyLinkModel> extends DefaultLinkFactory {
	constructor() {
		super('link');
	}

	generateReactWidget(event:any): JSX.Element {
		return <MyLinkWidget link={event.model} diagramEngine={this.engine} />;
	}

	generateModel(): MyLinkModel {
		return new MyLinkModel();
	}

	generateLinkSegment(model: Link, selected: boolean, path: string) {
		return (
			<S.Path
				selected={selected}
				stroke={selected ? model.getOptions().selectedColor : model.getOptions().color}
				strokeWidth={model.getOptions().width}
				d={path}
			/>
		);
	}
}