import * as React from 'react';
import { ChooseNodeModel } from './ChooseNodeModel';
import { ChooseNodeWidget } from './ChooseNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';

export class ChooseNodeFactory extends AbstractReactFactory<ChooseNodeModel, DiagramEngine> {
	constructor() {
		super('choose');
	}

	generateReactWidget(event: any): JSX.Element {
		return <ChooseNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event: any): ChooseNodeModel {
		return new ChooseNodeModel();
	}
}
