import * as React from 'react';
import { SupplierNodeModel } from './SupplierNodeModel';
import { SupplierNodeWidget } from './SupplierNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';

export class SupplierNodeFactory extends AbstractReactFactory<SupplierNodeModel, DiagramEngine> {
	constructor() {
		super('supplier');
	}

	generateReactWidget(event: any): JSX.Element {
		return <SupplierNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event: any): SupplierNodeModel {
		return new SupplierNodeModel();
	}
}
