import React from 'react';
import './App.scss';
import { NodeMap } from '../NodeMap/NodeMap';
import { Login } from '../Login/Login';
import { Dashboard } from '../Dashboard/Dashboard';
import { PrivateRoute } from '../../fragments/PrivateRoute/PrivateRoute'

import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import { ProvideAuth } from '../../services/AuthContext';
import { ProvideAppContext } from '../../services/AppContext';
import { Header } from '../../fragments/Header/Header';
import { ReportList } from '../ReportList/ReportList';
import { ResetPassword } from '../ResetPassword/ResetPassword';
import { GeoMap } from '../GeoMap/GeoMap';

function App() {
	return (
		<ProvideAuth>
			<ProvideAppContext>
				<Router basename="/manager">
					<Header />
					<Switch>
						<Route path="/login/:registered?" children={<Login />} />
						<Route path="/resetpassword">
							<ResetPassword />
						</Route>
						<PrivateRoute path="/nodes" component={NodeMap} />
						<PrivateRoute path="/geomap" component={GeoMap} />
						<PrivateRoute path="/dashboard" component={Dashboard} />
						<PrivateRoute path="/report-suppliers" component={ReportList} type="supplier" />
						<PrivateRoute path="/report-shipping" component={ReportList} type="shipping" />
						<PrivateRoute path="/report-products" component={ReportList} type="product" />
						<PrivateRoute path="/report-customers" component={ReportList} type="customer" />
						<PrivateRoute exact path="/" component={Dashboard} />
						
					</Switch>
				</Router>
			</ProvideAppContext>
		</ProvideAuth>
	);
}

export default App;
