import React, { useEffect } from 'react';
import styles from './ResetPassword.module.scss';
import { useForm } from "react-hook-form";
import { useAuth } from '../../services/AuthContext';
import { getResetPasswordErrorFromCode } from '../../services/Helpers';



type routerState = {
	from?: string
}

export function ResetPassword(props: any) {
	const { register, handleSubmit } = useForm();
	const auth = useAuth();
	const [error, setError] = React.useState('');
	const [successMessage, setSuccessMessage] = React.useState(false);
	const onSubmit = (event: any) => {
		auth.resetPassword(event.email_address, (errorCode:string) => {
			let error = getResetPasswordErrorFromCode(errorCode);
			setError(error);
			if(error === '') {
				setSuccessMessage(true);
			}
			// if (error === '') {
			// 	history.push("/dashboard");
			// }
		})
	}

	useEffect(() => {
		//auth.checkLoginStatus(onAuthStatusFinish);
	}, []);

	// const onAuthStatusFinish = (loggedIn: boolean) => {
	// 	if (loggedIn) {
	// 		if (typeof state != 'undefined' && typeof state.from != 'undefined') {
	// 			history.push(state.from);
	// 		}
	// 		else {
	// 			history.push("/dashboard");
	// 		}
	// 	}
	// }


	return (
		<div className={styles.loginPageWrapper}>
			<div  className={styles.loginFormWrapper}>
				<form onSubmit={handleSubmit(onSubmit)}>
				
					<h1>PASSWORD RESET</h1>
					{ error !== '' &&
						<div className={styles.error}>{ error }</div>
					}
					{successMessage &&
						<div className={styles.regsiteredNotice}>Password Reset Link has been sent.</div>

					}
					<label className={[styles.label, styles.username].join(' ')}>
						
						<input type="text" {...register("email_address")} placeholder="Email"/>
					</label>

					<input className={[styles.submit].join(' ')} type="submit" value="SEND PASSWORD RESET EMAIL" />
					<a href="/manager/login" className={styles.registerBtn}>BACK TO SIGN IN</a>

				</form>
			</div>
		</div>
	);
}
