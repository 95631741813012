import { useAppContext } from "../../services/AppContext";
import style from './Modal.module.scss';
import { IoClose } from "react-icons/io5";

interface ModalProps {
}

export const Modal = (props: ModalProps) => {
  let appState = useAppContext();

  const overlayClick = (e:any) => {
    appState.hideModal();

    e.stopPropagation();
  }

  const contentClick = (e:any) => {
    e.stopPropagation();
  }

  return (
    <div className={[style.modalOverlay,appState.modalOpen ? style.active : ''].join(' ')} /*onClick={overlayClick} disabled because chrome doesnt track if click started on child*/>
      <div className={[style.modalBox, 'clearfix'].join(' ')} onClick={contentClick}>
        <div className={style.modalClose} onMouseDownCapture={overlayClick}><IoClose /></div>
        <div className={style.modalContent}>
          {appState.modalContent}
        </div>
      </div>
    </div>
  );
};