export function handleResponse(response:Response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          if ([401, 403].indexOf(response.status) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
              //AuthService.logout();
              window.location.reload();
          }

          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export function getResetPasswordErrorFromCode(code: string) {
  var error = '';
  switch (code) {
    case 'empty_username': {
      error = "Error: no email found"

      break;
    }
    
    default:
      error = '';
      break;
  }
  
  return error
}
export function getLoginErrorFromCode(code: string) {
  var error = '';
  switch (code) {
    case '[jwt_auth] incorrect_password': {
      error = "Error: incorrect password"

      break;
    }
    case '[jwt_auth] invalid_username': {
      error = "Error: invalid username"

      break;
    }
  }
  
  return error
}