import { DefaultPortModel, PortModel } from "@projectstorm/react-diagrams";
import { MyLinkModel } from "../links/MyLinkModel";

export class MyPortModel extends DefaultPortModel {
	createLinkModel(): MyLinkModel {
		return new MyLinkModel();
	}

	canLinkToPort(port: PortModel): boolean {
		if (port instanceof MyPortModel) {
			return this.options.in !== port.getOptions().in;
		}
		return true;
	}
}