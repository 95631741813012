import * as React from 'react';
import { CustomerNodeModel } from './CustomerNodeModel';
import { CustomerNodeWidget } from './CustomerNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';

export class CustomerNodeFactory extends AbstractReactFactory<CustomerNodeModel, DiagramEngine> {
	constructor() {
		super('customer');
	}

	generateReactWidget(event: any): JSX.Element {
		return <CustomerNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event: any): CustomerNodeModel {
		return new CustomerNodeModel();
	}
}
