import React, { useEffect } from 'react';
import styles from './Login.module.scss';
import { useForm } from "react-hook-form";
import { useAuth } from '../../services/AuthContext';
import { getLoginErrorFromCode } from '../../services/Helpers';
import { useHistory, useLocation,useParams } from "react-router-dom";
import { Link } from "react-router-dom";


type RouterState = {
	from?: string
}

type RouterParams = {
	registered?: string
}

export function Login(props: any) {
	const { register, handleSubmit } = useForm();
	const auth = useAuth();
	const [error, setError] = React.useState('');
	const history = useHistory();
	const { state } = useLocation<RouterState>();
	const params = useParams<RouterParams>();

	const onSubmit = (event: any) => {
		auth.login(event.username, event.password, (errorCode:string) => {
			let error = getLoginErrorFromCode(errorCode);
			setError(error);

			if (error === '') {
				console.log('pushing history');
				history.push("/dashboard");
			}
			console.log('done login');
		})
	}

	useEffect(() => {
		auth.checkLoginStatus(onAuthStatusFinish);
	}, []);

	const onAuthStatusFinish = (loggedIn: boolean) => {
		if (loggedIn) {
			if (typeof state != 'undefined' && typeof state.from != 'undefined') {
				history.push(state.from);
			}
			else {
				history.push("/dashboard");
			}
		}
	}


	return (
		<div className={styles.loginPageWrapper}>
			<div  className={styles.loginFormWrapper}>
				<form onSubmit={handleSubmit(onSubmit)}>
				{typeof params.registered !== 'undefined' &&
         <div className={styles.regsiteredNotice}>You have registered successfully. Please Sign In</div>
      }
					<h1>SIGN IN</h1>
					{ error !== '' &&
						<div className={styles.error}>{ error }</div>
					}
					<label className={[styles.label, styles.username].join(' ')}>
						
						<input type="text" {...register("username")} placeholder="Email"/>
					</label>
					<label className={[styles.label, styles.password].join(' ')}>
						
						<input type="password" {...register("password")} placeholder="Password"/>
					</label>
					<input className={[styles.submit].join(' ')} type="submit" value="SIGN IN TO AN EXISTING ACCOUNT" />
					<a href="/register" className={styles.registerBtn}>SET-UP A NEW ACCOUNT</a>
					<p className={styles.forgotPasswordLinkWrapper}> <Link to="/resetpassword" className={styles.forgotPasswordLink}>Forgot your password?</Link></p>
				</form>
			</div>
		</div>
	);
}
