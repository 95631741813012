import React from "react";
import { useFormContext, UseFormRegisterReturn, UseFormReturn } from "react-hook-form";
import styles from './FormField.module.scss';

export interface FormFieldData  {
  type: 'field';
  input: string;
  id: string;
  label: string;
  options?:string[];
  parentId:string;
  repeaterNum:number;
  defaultValue?:string;
  required?: boolean;
  pattern?: string;
  max?: number;
  min?: number;
};

interface FormFieldProps {
  formMethods: UseFormReturn<Record<string, any>>,
  fieldData: FormFieldData,
}

export const FormField = (props: FormFieldData) => {
  const methods = useFormContext();
  
  return (
    <FormFieldInner fieldData={props} formMethods={methods}></FormFieldInner>
  );
};



const FormFieldInner = 
  (props: FormFieldProps) => {
    let validation:any = {};
    let stylesList:string[] = [
      styles.input,
    ];

    let errors = props.formMethods.formState.errors;

    if (typeof errors[props.fieldData.parentId] !== 'undefined' && 
      typeof errors[props.fieldData.parentId][props.fieldData.repeaterNum] !== 'undefined' && 
      typeof errors[props.fieldData.parentId][props.fieldData.repeaterNum][props.fieldData.id] !== 'undefined') {
        stylesList.push(styles.error);
    }

    if (typeof props.fieldData.required !== 'undefined' && props.fieldData.required) {
      validation.required = props.fieldData.required;
      stylesList.push(styles.required);
    }

    if (typeof props.fieldData.pattern !== 'undefined') {
      validation.pattern = new RegExp(props.fieldData.pattern);
    }

    if (typeof props.fieldData.min !== 'undefined') {
      if (props.fieldData.input === 'number') {
        validation.min = props.fieldData.min;
      }
      else if (props.fieldData.input === 'text' || props.fieldData.input === 'textarea') {
        validation.minLength = props.fieldData.min;
      }
    }

    if (typeof props.fieldData.max !== 'undefined') {
      if (props.fieldData.input === 'number') {
        validation.max = props.fieldData.max;
      }
      else if (props.fieldData.input === 'text' || props.fieldData.input === 'textarea') {
        validation.maxLength = props.fieldData.max;
      }
    }

    return (
      <div className={stylesList.join(' ')} key={props.fieldData.id}>
        <label>{props.fieldData.label}</label>
        {
          (() => {
            let registration:UseFormRegisterReturn;
            let defaultValue = '';
            let parentId = props.fieldData.parentId + '[' + props.fieldData.repeaterNum + ']';

            registration = props.formMethods.register(`${parentId}.${props.fieldData.id}` as const, validation);

            if (typeof props.fieldData.defaultValue != 'undefined') {
              defaultValue = props.fieldData.defaultValue;
            }

            switch (props.fieldData.input) {
              case "text":
                return(<input type="text" defaultValue={defaultValue} {...registration} />);
              case "disabled_text":
                return(<input type="text" disabled={true} defaultValue={defaultValue} />);
              case "number":
                return(<input type="number" defaultValue={defaultValue} {...registration} />);
              case "textarea":
                return(<textarea defaultValue={defaultValue} {...registration}></textarea>);
              case "boolean":
                return(<input type="checkbox" defaultChecked={defaultValue === 'true'}  {...registration} />);
              case "select":
                return(
                  <select defaultValue={defaultValue} {...registration}>
                    {
                      (() => {
                        if  (typeof props.fieldData.options != 'undefined') {
                          let optionOutput = [];
                          for (let opt of props.fieldData.options) {
                            optionOutput.push(<option>{opt}</option>);
                          }
                          return(optionOutput);

                        }
                      })()
                    }
                  </select>
                );
              default:
                return(<></>);
            }
          })()
        }
      </div>
    );
  }