import React, { useEffect, useState } from 'react';
import styles from './NodeMap.module.scss';

import { DiagramModel } from '@projectstorm/react-diagrams';
import {
	CanvasWidget
} from '@projectstorm/react-canvas-core';

import { DiagramState } from '../../react-diagrams/states/DiagramState';
import { ProductNodeFactory } from '../../react-diagrams/entities/nodes/product/ProductNodeFactory';
import { CustomerNodeFactory } from '../../react-diagrams/entities/nodes/customer/CustomerNodeFactory';
import { ChooseNodeFactory } from '../../react-diagrams/entities/nodes/choose/ChooseNodeFactory';
import { SupplierNodeFactory } from '../../react-diagrams/entities/nodes/supplier/SupplierNodeFactory';
import { ShippingNodeFactory } from '../../react-diagrams/entities/nodes/shipping/ShippingNodeFactory';
import { MyLinkFactory } from '../../react-diagrams/entities/links/MyLinkFactory';
import { MyPortFactory } from '../../react-diagrams/entities/ports/MyPortFactory';
import { useAuth } from '../../services/AuthContext';
import { NodeViewSidebar, ServerParentList} from '../../fragments/NodeViewSidebar/NodeViewSidebar';
import { useAppContext } from '../../services/AppContext';
//import { MyLabelFactory } from '../../react-diagrams/entities/labels/MyLabelFactory';


interface NodeMapProps {
}
export function NodeMap(props: NodeMapProps) {
	const auth = useAuth();
	const app = useAppContext();
	const [parentList, setParentList] = useState<ServerParentList>({customers: [], products: []});

	useEffect(() => {
		app.diagramEngine.getNodeFactories().registerFactory(new CustomerNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new ProductNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new ChooseNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new SupplierNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new ShippingNodeFactory());
		app.diagramEngine.getLinkFactories().registerFactory(new MyLinkFactory());
		//app.diagramEngine.getLabelFactories().registerFactory(new MyLabelFactory());
		app.diagramEngine.getPortFactories().registerFactory(new MyPortFactory());
		app.diagramEngine.getStateMachine().pushState(new DiagramState());

		getDiagram('');
	}, []);

	const getDiagram = (id: string) => {
		auth.getNodeDiagram(id)
			.then((result:any) => {
				var model2 = new DiagramModel();
				model2.deserializeModel(result.data.model, app.diagramEngine);

				app.diagramEngine.setModel(model2);

				setParentList(result.data.parents);

				app.setCurrParentID(result.data.currParentID);
			});
	}

	return (
		<div className={styles.canvasWrapper}>
			<NodeViewSidebar parentList={parentList} changeProduct={getDiagram} />

			<CanvasWidget className={styles.canvasWidget} engine={app.diagramEngine} />
		</div>
	);
}