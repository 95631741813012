import React, { useEffect, useState } from "react";
import { FormField, FormFieldData } from "../FormField/FormField";
import styles from './FormGroup.module.scss';
import { IoAddCircle } from "react-icons/io5";

export interface FormGroupData {
  type: 'group';
  label?: string;
  form: Array<FormFieldData>;
  id:string;
  repeaterLabel?: string;
  values?: {[key: string]: string}[];
}

export const FormGroup = (props: FormGroupData) => {
  const [fields, setFields] = useState<JSX.Element[]>([]);
  const [repeatCount, setrepeatCount] = useState<number>(0);

  const get_fields = (parentId:string, values: {[key: string]: string}, localRepeatCount: number):JSX.Element => {
    let fields:JSX.Element[] = [];
    let value = '';

    for(let field of props.form) {
      value = '';
      if (typeof values[field.id] != 'undefined') {
        value = values[field.id];
      }
      else if (typeof field.defaultValue != 'undefined') {
        value = field.defaultValue;
      }

      fields.push(<FormField {...field} parentId={parentId} repeaterNum={localRepeatCount} key={field.id} defaultValue={value} />);
    }
    return <div key={localRepeatCount} className={styles.repeaterCell}>{fields}</div>;
  }

  const repeatField = (values: {[key: string]: string}, localRepeatCount:number) => {
    setFields(prevFields => [...prevFields, get_fields(props.id, values, localRepeatCount)]);
    setrepeatCount(localRepeatCount + 1);
  }

  useEffect(() => {
    let localRepeatCount = 0;
    setFields([]);
    if (typeof props.values !== 'undefined' && props.values.length) {
      for(let value of props.values) {
        repeatField(value, localRepeatCount);
        localRepeatCount++;
      }
    }
    else {
      repeatField({}, localRepeatCount);
    }
  }, [props])

  return (
    <div className={styles.formGroup}>
      { typeof props.label != 'undefined' && <div className={styles.title}>{props.label}</div> }
      <div className={styles.repeater}>
        <div className={styles.repeaterCells}>
        {
          fields
        }
        </div>
        {
          (() => {
            if (typeof props.repeaterLabel !== 'undefined') {
              return (<div className={styles.repeaterLabel} onClick={() => repeatField({}, repeatCount)}><IoAddCircle /> {props.repeaterLabel}</div>);
            }
          })()
        }
        
      </div>
    </div>
  );
};