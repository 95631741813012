import * as React from 'react';
import { ShippingNodeModel } from './ShippingNodeModel';
import { ShippingNodeWidget } from './ShippingNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';

export class ShippingNodeFactory extends AbstractReactFactory<ShippingNodeModel, DiagramEngine> {
	constructor() {
		super('shipping');
	}

	generateReactWidget(event: any): JSX.Element {
		return <ShippingNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event: any): ShippingNodeModel {
		return new ShippingNodeModel();
	}
}
