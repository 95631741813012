import { DefaultLinkModel } from "@projectstorm/react-diagrams";
//import { MyLabelModel } from "../labels/MyLabelModel";

export class MyLinkModel extends DefaultLinkModel {
	constructor() {
			super({
					type: 'link',
			});
	}
/*
	addMyLabel() {
		let labelOb = new MyLabelModel();
		return super.addLabel(labelOb);
	}*/
}