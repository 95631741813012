import { ReactNode } from "react";
import { useAuth } from "../../services/AuthContext";

type MyProps = {
  children: ReactNode,
  showWhenLoggedIn: boolean,
};

export function LoggedInShow (props: MyProps) {
  const auth = useAuth();

  let show = props.children;

  if ((auth.loggedIn && !props.showWhenLoggedIn) || (!auth.loggedIn && props.showWhenLoggedIn)) {
    show = <></>
  }

  return (<>{show}</>);
}