import * as React from 'react';
import * as _ from 'lodash';
import { DiagramEngine, LinkModel, PortModel, PortModelGenerics } from '@projectstorm/react-diagrams-core';
import { ChooseNodeModel } from './ChooseNodeModel';
import { ShippingNodeModel } from '../shipping/ShippingNodeModel';
import { SupplierNodeModel } from '../supplier/SupplierNodeModel';
import { DefaultPortLabel, DefaultPortModel } from '@projectstorm/react-diagrams-defaults';
import styled from '@emotion/styled';
import { LinkModelGenerics } from '@projectstorm/react-diagrams';
import { useAuth } from '../../../../services/AuthContext';
import { useAppContext } from '../../../../services/AppContext';
import { useEffect, useState } from 'react';
import styles from './ChooseNodeWidget.module.scss';
import Select from 'react-select';



namespace S {
	export const Node = styled.div<{ background: string; selected: boolean }>`
		background-color: ${(p) => p.background};
		border-radius: 5px;
		font-family: sans-serif;
		color: white;
		border: solid 2px black;
		overflow: visible;
		font-size: 11px;
		border: solid 2px ${(p) => (p.selected ? 'rgb(0,192,255)' : 'black')};
	`;

	export const Title = styled.div`
		background: rgba(0, 0, 0, 0.3);
		display: flex;
		white-space: nowrap;
		justify-items: center;
	`;

	export const TitleName = styled.div`
		flex-grow: 1;
		padding: 5px 5px;
	`;

	export const Form = styled.div`
	`;

	export const Ports = styled.div`
		display: flex;
		background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
	`;

	export const PortsContainer = styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		&:first-of-type {
			margin-right: 10px;
		}

		&:only-child {
			margin-right: 0px;
		}
	`;
}

export interface DefaultNodeProps {
	node: ChooseNodeModel;
	engine: DiagramEngine;
}

/**
 * Default node that models the DefaultNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export function ChooseNodeWidget (props: DefaultNodeProps) {
	const auth = useAuth();
	const appState = useAppContext();
	const [validNodes, setValidNodes] = useState<{name:string, type:string, id:string}[]>([]);

	useEffect(() => {
		auth.getValidNodes().then((nodes:any) => {
			setValidNodes(nodes.data);
		})
	}, [])

	const generatePort = (port:DefaultPortModel) => {
		return <DefaultPortLabel engine={props.engine} port={port} key={port.getID()} />;
	};


	const handleNodeTypeSelect = (event:any) =>{
		console.log(event);
		changeType(event);
	}

	let nodeOptions:{}[] = validNodes.map(s =>({
        value:s.id,
        label:s.name
    }));
	let newNodeOptions:{}[] = [
		{
			value:'shipping',
			label:'New Shipping Node'
		},
		{
			value:'supplier',
			label:'New Supplier Node'
		}
	];

	let nodeOptionsList:{}[] = [
		{
			label:'New Nodes',
			options:newNodeOptions,
		},
		{
			label:'Existing Nodes',
			options:nodeOptions
		}
	];


	const formatGroupLabel = (data:any) => (
		<div className={styles.nodeSelectOptionGroup}>
		  <span className={styles.nodeSelectOptionLabel}>{data.label}</span>
		  <span> ({data.options.length})</span>
		</div>
	  );

	const changeType = (event:any) => {
		let sourcePorts:Array<PortModel<PortModelGenerics>> = [];

		for(let port of props.node.getInPorts()) {
			let linkArray = Object.values(port.getLinks());
			for(let link of linkArray ) {
				sourcePorts.push(link.getSourcePort());
			}
		}

		let newNode = null;
		let newLinks:Array<LinkModel<LinkModelGenerics>> = [];

		if (event.value === 'shipping') {
			newNode = new ShippingNodeModel({
				name: 'Untitled', 
				parentID: appState.currParentID
			});
		}
		else if (event.value === 'supplier') {
			newNode = new SupplierNodeModel({
				name: 'Untitled', 
				parentID: appState.currParentID
			});
		}
		else {
			let id = event.value;

			for(let node of validNodes) {
				if (node.id === id){
					if (node.type === 'supplier') {
						newNode = new SupplierNodeModel({
							name: node.name, 
							parentID: appState.currParentID,
							modelID: id,
						});
					}
					else if (node.type === 'shipping') {
						newNode = new ShippingNodeModel({
							name: node.name, 
							parentID: appState.currParentID,
							modelID: id,
						});
					}
				}
			}

		}

		if (newNode === null ) {
			return;
		}

		let inPort = newNode.addInPort('In');
		newNode.addOutPort('Out');

		newNode.setPosition(props.node.getX(), props.node.getY());

		for(let sourcePort of sourcePorts) {

			let newLink = sourcePort.createLinkModel();
			newLink?.setSourcePort(sourcePort);
			newLink?.setTargetPort(inPort);

			sourcePort.reportPosition();
			inPort.reportPosition();

			props.engine.getModel().addNode(newNode);
			if (newLink != null) {
				props.engine.getModel().addLink(newLink);
				newLinks.push(newLink);
			}
		}

		let itemsToSave:Array<any> = [];

		for (let link of newLinks) {
			itemsToSave.push(link.serialize());
		}
		itemsToSave.push(newNode.serialize());

		auth.saveItems(itemsToSave).then(() => {
		});

		props.node.remove();
		props.engine.repaintCanvas();
	}

	return (
		<S.Node
			data-default-node-name={props.node.getOptions().name}
			selected={props.node.isSelected()}
			className={styles.nodeWrapper}
			background="rgb(0,192,255)">
			<S.Title className={styles.nodeTitle}>
				<S.TitleName>{props.node.getOptions().name}</S.TitleName>
			</S.Title>
			<S.Form>
				<Select  formatGroupLabel={formatGroupLabel} className={styles.selectDropdown} options={nodeOptionsList} onChange={event =>handleNodeTypeSelect(event)}/>
			</S.Form>
			<S.Ports className={styles.portWrapper}>
				<S.PortsContainer className={styles.portIn}>{_.map(props.node.getInPorts(), generatePort)}</S.PortsContainer>
				<S.PortsContainer className={styles.portOut}>{_.map(props.node.getOutPorts(), generatePort)}</S.PortsContainer>
			</S.Ports>
		</S.Node>
	);
}
