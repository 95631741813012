import React from "react";
import { Modal } from '../fragments/Modal/Modal';
import createEngine, { 
	DiagramModel,
	DiagramEngine,
} from '@projectstorm/react-diagrams';


export function ProvideAppContext(children: any) {
  const app = useProvideAppContext();


  return (
    <appContext.Provider value={app}>
      <Modal />
      {children.children}
    </appContext.Provider>
  )
}

export const useAppContext = () => {
  const context = React.useContext(appContext)
  if (context === undefined) {
    throw new Error('useAppContext must be used within a useAppContextProvider');
  }
  return context;
};


export function useProvideAppContext(): AppState {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(<div></div>);
  const [currParentID, setCurrParentID] = React.useState('');
	const [diagramEngine, setDiagramEngine] = React.useState(() => {
		let engine = createEngine({
			registerDefaultDeleteItemsAction: false
		});
		const model = new DiagramModel();

		engine.setModel(model);

		return engine;
	});

  const showModal = () => {
    document.body.classList.add("no-scroll")
    setModalOpen(true);
  }

  const hideModal = () => {
    document.body.classList.remove("no-scroll")
    setModalOpen(false);
  }

  return {
    showModal,
    hideModal,
    setModalContent,
    modalContent,
    modalOpen,
    setDiagramEngine,
    diagramEngine,
    setCurrParentID,
    currParentID
  }
}

export const appContext = React.createContext<AppState|undefined>(undefined);



interface AppState {
  showModal: Function;
  hideModal: Function;
  setModalContent: Function;
  modalContent: JSX.Element;
  modalOpen: boolean;
  setDiagramEngine: Function;
  diagramEngine: DiagramEngine;
  setCurrParentID: Function;
  currParentID: string;
};