import * as _ from 'lodash';
import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams-core';
import { MyPortModel } from '../../ports/MyPortModel';
import { BasePositionModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';

export interface ShippingNodeModelOptions extends BasePositionModelOptions {
	name: string;
	parentID: string;
	modelID?: string;
}

export interface ShippingNodeModelGenerics extends NodeModelGenerics {
	OPTIONS: ShippingNodeModelOptions;
}

export class ShippingNodeModel extends NodeModel<ShippingNodeModelGenerics> {
	protected portsIn: MyPortModel[];
	protected portsOut: MyPortModel[];
	protected parentID: string;

	constructor(options?:ShippingNodeModelOptions)  {
		if (typeof options == 'undefined') {
			options = {
				name: 'Untitled',
				parentID: '',
			};
		}


		super({
			type: 'shipping',
			...options
		});
		this.portsOut = [];
		this.portsIn = [];
		this.parentID = options.parentID;
	}

	doClone(lookupTable: {}, clone: any): void {
		clone.portsIn = [];
		clone.portsOut = [];
		super.doClone(lookupTable, clone);
	}

	removePort(port: MyPortModel): void {
		super.removePort(port);
		if (port.getOptions().in) {
			this.portsIn.splice(this.portsIn.indexOf(port), 1);
		} else {
			this.portsOut.splice(this.portsOut.indexOf(port), 1);
		}
	}

	addPort<T extends MyPortModel>(port: T): T {
		super.addPort(port);
		if (port.getOptions().in) {
			if (this.portsIn.indexOf(port) === -1) {
				this.portsIn.push(port);
			}
		} else {
			if (this.portsOut.indexOf(port) === -1) {
				this.portsOut.push(port);
			}
		}
		return port;
	}

	addInPort(label: string, after = true): MyPortModel {
		const p = new MyPortModel({
			in: true,
			name: label,
			label: label,
			alignment: PortModelAlignment.LEFT
		});
		if (!after) {
			this.portsIn.splice(0, 0, p);
		}
		return this.addPort(p);
	}

	addOutPort(label: string, after = true): MyPortModel {
		const p = new MyPortModel({
			in: false,
			name: label,
			label: label,
			alignment: PortModelAlignment.RIGHT
		});
		if (!after) {
			this.portsOut.splice(0, 0, p);
		}
		return this.addPort(p);
	}

	deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
		this.options.name = event.data.name;
		this.options.parentID = event.data.parentID;
		this.portsIn = _.map(event.data.portsInOrder, (id) => {
			return this.getPortFromID(id);
		}) as MyPortModel[];
		this.portsOut = _.map(event.data.portsOutOrder, (id) => {
			return this.getPortFromID(id);
		}) as MyPortModel[];
	}

	serialize(): any {
		return {
			...super.serialize(),
			name: this.options.name,
			parentID: this.options.parentID,
			modelID: this.options.modelID,
			portsInOrder: _.map(this.portsIn, (port) => {
				return port.getID();
			}),
			portsOutOrder: _.map(this.portsOut, (port) => {
				return port.getID();
			})
		};
	}

	getInPorts(): MyPortModel[] {
		return this.portsIn;
	}

	getOutPorts(): MyPortModel[] {
		return this.portsOut;
	}
}
