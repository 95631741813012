import { AbstractModelFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { MyPortModel } from './MyPortModel';

export class MyPortFactory extends AbstractModelFactory<MyPortModel, DiagramEngine> {
	constructor() {
		super('port');
	}

	generateModel(): MyPortModel {
		return new MyPortModel({
			name: 'unknown'
		});
	}
}
